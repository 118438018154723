angular
    .module('MyHippoProducer.Services')
    .factory('IncentivesService', function (GraphqlService) {
        function fetch(query) {
            return GraphqlService.fetch(query).then((response) => response);
        }
        return {
            fetchIncentives: (query) => fetch(query),
        };
    });
